import classes from "./ChoosePayment.module.css";
import PaymentPlan from "./PaymentPlan";
import logo from "./../../img/Logo.svg";
import React, {useEffect} from "react";
import {useNavigate} from "react-router";

const images = [
    require("./../../img/yabancicekleri.jpg"),
    require("./../../img/kurulusosman.jpg"),
    require("./../../img/karasevda.jpg"),
    require("./../../img/muhtesemyuzyil.jpg"),
    require("./../../img/kurtseyitvesura.jpg"),
    require("./../../img/hercai.webp"),
];

const ChoosePayment = () => {
    const navigate = useNavigate();

    useEffect(() => {
        (function (d, w, c) {
            w.BrevoConversationsID = '634da3fd9246621bcf4be71d';
            w[c] = w[c] || function () {
                (w[c].q = w[c].q || []).push(arguments);
            };
            var s = d.createElement('script');
            s.async = true;
            s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
            if (d.head) d.head.appendChild(s);
        })(document, window, 'BrevoConversations');

        return () => {
            const script = document.querySelector('script[src="https://conversations-widget.brevo.com/brevo-conversations.js"]');
            if (script) {
                script.remove();
            }
        };
    }, []);

    // Sonsuz döngüdeki görselleri oluşturma
    const createInfiniteLoopImages = (images, loopCount) => {
        let loopedImages = [];
        for (let i = 0; i < loopCount; i++) {
            loopedImages = loopedImages.concat(images);
        }
        return loopedImages;
    };

    // Görselleri her sütun için tekrar etmeyecek şekilde sıralama
    const distributeImages = (images) => {
        const leftImages = [];
        const centerImages = [];
        const rightImages = [];
        for (let i = 0; i < images.length; i++) {
            if (i % 3 === 0) {
                leftImages.push(images[i]);
            } else if (i % 3 === 1) {
                centerImages.push(images[i]);
            } else {
                rightImages.push(images[i]);
            }
        }
        return {leftImages, centerImages, rightImages};
    };

    const loopedImages = createInfiniteLoopImages(images, 10);
    const {leftImages, centerImages, rightImages} = distributeImages(loopedImages);

    return (
        <div className={classes.CreateTop}>
            <div className={classes.row}>
                <div className={classes.left}>
                    <div
                        className={classes.logo}
                        onClick={() => navigate("/")}
                        style={{cursor: "pointer"}}
                    >
                        <img src={logo} alt="logo"/>
                        <h2>WatchTurks</h2>
                    </div>
                    <PaymentPlan/>
                </div>
                <div className={classes.right}>
                    <div className={classes["image-grid"]}>
                        <div className={`${classes.column} ${classes["left-column"]}`}>
                            {leftImages.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`}/>
                            ))}
                        </div>
                        <div className={`${classes.column} ${classes["center-column"]}`}>
                            {centerImages.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`}/>
                            ))}
                        </div>
                        <div className={`${classes.column} ${classes["right-column"]}`}>
                            {rightImages.map((image, index) => (
                                <img key={index} src={image} alt={`Slide ${index}`}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChoosePayment;
