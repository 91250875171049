import React, {useContext} from 'react';
import classes from './WatchLater.module.css';
import watchLater from './../../../img/watchlater.svg';
import CaretLeft from './../../../img/CaretLeft.svg';
import NotSubscribe from '../../NotSubscribe/NotSubscribe';
import {leftMenu} from '../ProfileMain';
import {useQuery} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import {AuthContext} from '../../../context/authContext';
import MovieCard from '../../AllMovies/MovieCard';
import SeriesCard from '../../AllSeries/SeriesCard';
import axios from 'axios';

const WatchLater = () => {
    const ctx = useContext(leftMenu);
    const {currentUser} = useContext(AuthContext);

    const showSidebar = () => {
        ctx();
    };
    const {data} = useQuery({
        queryKey: ['watchList'],
        queryFn: () => {
            return axios.get(`https://admin.watchturks.online/api/user/${currentUser?.body.user?.user_id}/wishlist`);
        },
    });

    return (
        <div className={classes.watchLaterTop}>
            <>
                {currentUser?.body.user.user_status ? (
                    <>
                        <div className={classes.headerContent}>
                            <img
                                onClick={showSidebar}
                                className={classes.CaretLeft}
                                src={CaretLeft}
                                alt="CaretLeft"
                            />
                            <img
                                className={classes.bookmarkHeader}
                                src={watchLater}
                                alt="watchLater"
                            />
                            <h1>Favorites </h1>
                        </div>
                        <div className={classes.row}>
                            {data &&
                                data?.data.body.wactchLater.map((movieAndSeries) => (
                                    // <Card watchLaterMovie={item} />
                                    <>
                                        {movieAndSeries.movie && (
                                            <MovieCard movie={movieAndSeries.movie} history={true}/>
                                        )}
                                        {movieAndSeries.series && (
                                            <SeriesCard
                                                serie={movieAndSeries?.series}
                                                history={true}
                                            />
                                        )}
                                    </>
                                ))}
                        </div>
                    </>
                ) : (
                    <NotSubscribe/>
                )}
                {/* {!currentUser?.body.user.user_status && <NotSubscribe />} */}
            </>
        </div>
    );
};
export default WatchLater;
