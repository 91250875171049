import React, { useContext } from "react";
import SubscriptionCardUi from "../SubscriptionCardUi/SubscriptionCardUi";
import CaretLeft from "./../../../img/CaretLeft.svg";
import classes from "./Subscription.module.css";
import { leftMenu } from "../ProfileMain";
import { AuthContext } from "../../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
    const { currentUser } = useContext(AuthContext);
    const subCtx = useContext(leftMenu);
    const navigate = useNavigate();

    const showSidebar = () => {
        subCtx();
    };

    const { data: packagesData, isLoading: isLoadingPackages } = useQuery({
        queryKey: ["packages"],
        queryFn: () => axios.get(`https://admin.watchturks.online/api/packages`),
    });

    const {
        data: subscriptionData,
        isLoading: isLoadingSubscription,
        isError: isSubscriptionError,
    } = useQuery({
        queryKey: ["currentSubscription", currentUser?.body.user.user_id],
        queryFn: () =>
            axios.get(
                `https://admin.watchturks.online/api/subscriptions/${currentUser?.body.user.user_id}/current`
            ),
        enabled: !!currentUser?.body.user.user_id,
    });

    const handleManageSubscription = async () => {
        try {
            const response = await axios.post(
                `https://admin.watchturks.online/api/subscriptions/${currentUser?.body.user.user_id}/manage`
            );
            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.error ||
                "Failed to redirect to subscription management page",
                {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            );
        }
    };

    const calculateRemainingDays = () => {
        if (!subscriptionData?.data?.end_date) {
            return 0;
        }

        const expiredDate = new Date(subscriptionData.data.end_date);
        const currentDate = new Date();

        const differenceInTime = expiredDate.getTime() - currentDate.getTime();

        if (differenceInTime <= 0) {
            return 0;
        }

        return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    };

    const handlePlanSelect = (pkgId) => {
        if (subscriptionData?.data?.source === "stripe" && subscriptionData.data.status === "active") {
            // Stripe üzerinden yönetim sayfasına yönlendir
            handleManageSubscription();
        } else {
            // Local veya aktif olmayan planlar için ödeme sayfasına yönlendir
            navigate(`/choosepayment?plan_id=${pkgId}`);
        }
    };
    return (
        <>
            <div className={classes.subscriptionTop}>
                {isLoadingSubscription ? (
                    <p>Loading subscription...</p>
                ) : isSubscriptionError || subscriptionData?.data?.message === "No subscription found." ? (
                    <div className={classes.inner}>
                        <img
                            className={classes.CaretLeft}
                            onClick={showSidebar}
                            src={CaretLeft}
                            alt="CaretLeft"
                        />
                        <h1>No Active Subscription</h1>
                        <p>
                            You currently don't have an active subscription. Please choose
                            a plan to continue.
                        </p>
                        <div className={classes.morePlanWSub}>
                            {isLoadingPackages ? (
                                <p>Loading available plans...</p>
                            ) : (
                                packagesData?.data?.body?.packages.map((pkg) => (
                                    <SubscriptionCardUi
                                        key={pkg.id}
                                        subscriptionPkg={pkg}
                                        onPlanSelect={handlePlanSelect}
                                        hasSubscription={false}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={classes.inner}>
                        <img
                            onClick={showSidebar}
                            className={classes.CaretLeft}
                            src={CaretLeft}
                            alt="CaretLeft"
                        />
                        <h1>Your Plan</h1>
                        <p>
                            Watch all you want with English subs. Recommendations just for
                            you. Change or cancel your plan anytime.
                        </p>
                        <div className={classes.currentPlan}>
                            <div className={classes.planName}>
                                <h2>{subscriptionData.data.plan_name}</h2>
                                <h2>
                                    ${subscriptionData.data.price} / {subscriptionData.data.interval}
                                </h2>
                                <p style={{ margin: "0" }}>
                                    Valid until: {subscriptionData.data.end_date}
                                </p>
                                <p style={{ margin: "0" }}>
                                    Remaining: {calculateRemainingDays()} days
                                </p>
                                {subscriptionData.data.cancel_at_period_end && (
                                    <p style={{ color: "orange", margin: "0" }}>
                                        Cancellation Scheduled - Valid until: {subscriptionData.data.end_date}
                                    </p>
                                )}
                            </div>
                            <div className={classes.manageSub}>
                                {subscriptionData?.data?.source === "stripe" ? (
                                    <button
                                        className={classes.manageBtn}
                                        onClick={() => handleManageSubscription()}
                                    >
                                        Manage Subscription
                                    </button>
                                ) : (
                                    <p>Local Plan - Management Not Available</p>
                                )}
                            </div>

                        </div>

                        <div className={classes.morePlan}>
                            {isLoadingPackages ? (
                                <p>Loading available plans...</p>
                            ) : (
                                packagesData?.data?.body?.packages
                                    .filter(
                                        (pkg) =>
                                            pkg.id !== subscriptionData.data.plan_id
                                    )
                                    .map((pkg) => (
                                        <SubscriptionCardUi
                                            key={pkg.id}
                                            subscriptionPkg={pkg}
                                            onPlanSelect={handlePlanSelect}
                                            hasSubscription={!!subscriptionData.data}
                                        />
                                    ))
                            )}
                        </div>
                    </div>
                )}
            </div>

            <ToastContainer limit={1} />
        </>
    );
};

export default Subscription;