import React, { useContext } from "react";
import classes from "./SubscriptionCardUI.module.css";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";

const SubscriptionCardUi = ({ subscriptionPkg, onPlanSelect }) => {
    const { currentUser } = useContext(AuthContext);

    const handleClick = () => {
        onPlanSelect(subscriptionPkg.id);
    };

    return (
        <div className={classes.cardTop}>
            <h2>{subscriptionPkg.title}</h2>
            <h2>
                ${parseInt(subscriptionPkg.amount)}{" "}
                <span>
          /{subscriptionPkg.duration}{" "}
                    {subscriptionPkg.type === "days"
                        ? "days"
                        : subscriptionPkg.type === "monthly"
                            ? "months"
                            : subscriptionPkg.type === "yearly"
                                ? "years"
                                : ""}
        </span>
            </h2>
            <ul>
                <li>No Advertisement</li>
                <li>English Subtitles</li>
                <li>Unlimited Devices</li>
                <li>HD Resolution</li>
                <li>New Episodes Notifications</li>
            </ul>
            <button
                onClick={handleClick}
                className={classes.changeBtn}
            >
                <span>{currentUser?.body.user.plan_id ? "Change" : "Get Plan"}</span>
            </button>
        </div>
    );
};

export default SubscriptionCardUi;